import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    paddingTop: 20,
    paddingBottom: 80,
    paddingRight: 0,
    paddingLeft: 0,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: 60,
      paddingTop: 30,
      paddingBottom: '50px !important',
    },
  },
  bgOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,.7)',
    zIndex: 0,
  },
  maxWidth: {
    height: '100%',
    width: '100%',
    margin: '0 auto',
    maxWidth: 1440,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
    '& .MuiGrid-root': {
      width: '100%',
    },
    '& .keen-slider': {
      overflowX: 'hidden !important',
    },
  },
  srOnly: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  slideContainer: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'visible',
  },
  gridItem: {
    zIndex: 1,
  },
  entryWrapper: ({ noSlider }) => ({
    paddingTop: noSlider && theme.spacing(4),
    paddingBottom: noSlider && theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingTop: noSlider && theme.spacing(4),
      paddingBottom: noSlider && 0,
      width: '90%',
      margin: '0 auto',
    },
  }),
  entryOverline: {
    color: 'white',
    marginBottom: 50,
    [theme.breakpoints.up('md')]: {
      marginBottom: 65,
    },
  },
  authorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  authorImg: {
    width: '60px',
    height: '60px',
    minWidth: '60px',
    borderRadius: '100rem',
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginRight: 20,
  },
  authorText: {
    color: 'white',
    ...theme.typography.navigation,
  },
  authorTitle: {
    color: 'white',
    ...theme.typography.subtitle2,
  },
  customControl: {
    position: 'absolute',
    bottom: '-50px',
    left: 0,
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      left: '12.5%',
    },
  },
}))

export default useStyles
