import PropTypes from 'prop-types'
import SliderNew from '@components/slider-new'
import TestimonialMarkup from './testimonial-markupv2'
import useStyles from './testimonialv2.styles'

const TestimonialSlider = (props) => {
  const {
    testimonialsData,
  } = props

  const testimonials = testimonialsData?.testimonialEntriesCollection?.items || testimonialsData

  const styles = useStyles()
  const config = {
    initial: 0,
    mode: 'snap',
    orgin: 'center',
    breakpoints: {
      '(min-width: 600px)': {
        loop: false,
        slides: { perView: 1, spacing: 200 },
      },
    },
  }

  return (
    <div className={styles.sliderContainer}>
      <SliderNew
        className={styles.slider}
        controlClassName={styles.customControl}
        config={config}
      >
        {testimonials?.map((testimonial, i) => (
          <div key={`workingolf-testimonial-key${i + 1}`} className={styles.slideWrapper}>
            <TestimonialMarkup entryOverline={testimonialsData.entryOverline} {...testimonial} />
          </div>
        ))}

      </SliderNew>
    </div>
  )
}
TestimonialSlider.propTypes = {
  testimonialsSection: PropTypes.object,
  testimonialsData: PropTypes.object,
}

export default TestimonialSlider
