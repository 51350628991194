import PropTypes from 'prop-types'
import { IconTestimonialQuote } from '@components/atoms/icons'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useStyles from './testimonialv2.styles'

const TestimonialMarkup = (props) => {
  const {
    entryOverline,
    testimonial,
    author,
    authorTitle,
    authorImage,
    noSlider,
  } = props

  const styles = useStyles({ noSlider })
  const authorImgSrc = authorImage && authorImage.url ? authorImage.url : ''

  return (
    <Box className={styles.entryWrapper}>
      <Typography
        className={styles.entryOverline}
        style={{ display: entryOverline ? 'block' : 'none' }}
        variant="overline"
        variantMapping={{ h3: 'p' }}
      >
        {entryOverline}
      </Typography>
      <Box mb={4}>
        <IconTestimonialQuote />
      </Box>
      <Box mb={5}>
        <Typography className={styles.srOnly} variant="h3">Testimonial: </Typography>
        <Typography style={{ color: 'white' }} variant="h3" variantMapping={{ h3: 'p' }}>
          {testimonial}
        </Typography>
      </Box>
      {author && (
        <div className={styles.authorContainer}>
          { authorImgSrc && <div className={styles.authorImg} style={{ backgroundImage: `url(${authorImgSrc})` }} /> }
          <div>
            <div className={`${styles.authorText} ${styles.authorName}`}>
              {author}
            </div>
            <div className={`${styles.authorText} ${styles.authorTitle}`}>
              {authorTitle}
            </div>
          </div>
        </div>
      ) }
    </Box>
  )
}

TestimonialMarkup.propTypes = {
  entryOverline: PropTypes.string,
  testimonial: PropTypes.string,
  author: PropTypes.string,
  authorTitle: PropTypes.string,
  authorImage: PropTypes.object,
  noSlider: PropTypes.bool,
}

export default TestimonialMarkup
