import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import TestimonialSlider from './testimonial-sliderv2'
import TestimonialMarkup from './testimonial-markupv2'
import useStyles from './testimonialv2.styles'

const TestimonialV2 = (props) => {
  const {
    testimonialsData,
  } = props
  const isTestimonialV2 = testimonialsData?.__typename === 'TestimonialV2'

  const slider = isTestimonialV2
    ? testimonialsData?.testimonialEntriesCollection?.items?.length > 1
    : testimonialsData?.length > 1

  const firstTestimonial = isTestimonialV2
    ? testimonialsData?.testimonialEntriesCollection?.items[0]
    : testimonialsData[0]

  const defaultBgImgSrc = '/images/coach-testimonial-quote-bg.png'
  const bgImgSrc = testimonialsData?.backgroundImage?.url || defaultBgImgSrc
  const styles = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  return (
    <Box
      data-testid="testimonial-container"
      className={styles.root}
      style={{ paddingBottom: (slider && isMobile) ? 50 : 64, backgroundImage: `url(${bgImgSrc})` }}
    >
      <div className={styles.bgOverlay} />
      <Grid container className={styles.maxWidth}>
        <Grid item lg={8} className={styles.gridItem}>
          {slider
            ? <TestimonialSlider testimonialsData={testimonialsData} />
            : <TestimonialMarkup noSlider entryOverline={testimonialsData.entryOverline} {...firstTestimonial} />}
        </Grid>
      </Grid>
    </Box>
  )
}

TestimonialV2.propTypes = {
  testimonialsData: PropTypes.oneOfType([
    PropTypes.shape({
      __typename: PropTypes.string,
      entryOverline: PropTypes.string,
      testimonialEntriesCollection: PropTypes.shape({
        items: PropTypes.array,
      }),
      backgroundImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    PropTypes.array,
  ]),
}

TestimonialV2.defaultTypes = {
  showOnMobile: false,
}

export default TestimonialV2
